import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import api from 'appRedux/api';
import { Tooltip } from 'antd';
import '../Cash/carddetails/carddetails.scss';
import './allcards.scss';
import { openUpdateBox } from 'appRedux/actions/update';
import Loader from 'components/Loader';
import {
  Simplemodal,
  SimpleCancelmodal,
} from 'components/common/simplifiedmodal';
import larrow from 'assets/larrow.svg';
import rarrow from 'assets/rarrow.svg';
import { Empty } from 'components/common/empty/empty';
import VCardsTransactions from './cardsTransactions.js';
import Slider from 'react-slick';
import Ordercard from 'components/vesticards/selectcard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';
import { useUserData } from 'helpers/hooks';
import fundModalIcon from '../../../assets/vcards/fundCMIcon.png';
import vcardDot from '../../../assets/vcards/0.png';
import fundCard from '../../../assets/vcards/fund.png';
import showDet from '../../../assets/vcards/fund (1).png';
import freeze from '../../../assets/vcards/fund (2).png';
import delCard from '../../../assets/vcards/fund (3).png';
import { Cards } from 'components/virtualcards/cards';
import { Carddetails } from 'components/virtualcards/carddetails';
import { Vestirate } from 'components/common/vestirate/vestirate';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import ngn from '../../../assets/vcards/NG.png';
import arrowdropdown from '../../../assets/vcards/arrow-down.png';
import { SelectCurrencyThree } from 'components/bank/selectcurrencyTwo';
import Fundcard from '../Cash/carddetails/fundcard';

const VirtualcardPage = props => {
  const { refetch } = useUserData();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [mycardD, setMyCardD] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [active, setActive] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0); //
  const [ShowcardDelModal, setShowCardDelModal] = useState(false);
  const [fundCardModal, setFundCardModal] = useState(false);
  const [fundselectedCard, setFundSelectedCard] = useState(null);
  const [freezeModal, setFreezeModal] = useState(false);

  const allWallet = useSelector(state => state.wallets.wallets, _.isEqual);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  };

  const handleFundModal = () => {
    setFundCardModal(!fundCardModal);
  };

  const PrevButton = ({ onClick }) => (
    <button
      className="clickPrev"
      onClick={onClick}
      style={{ position: 'absolute', top: '40%', zIndex: '50', left: '-70px' }}
      disabled={currentSlide === mycardD.length} // Disable at the first slide
    >
      <img src={larrow} alt="left-arrow" />
    </button>
  );

  const NextButton = ({ onClick }) => (
    <button
      className="clickRight"
      onClick={onClick}
      style={{ position: 'absolute', top: '40%', zIndex: '5', right: '-70px' }}
      disabled={currentSlide === mycardD.length - 1} // Disable at the last slide
    >
      <img src={rarrow} alt="" />
    </button>
  );

  const settings = {
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    dots: true,
    infinite: false, //
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const lesscardsettings = {
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    dots: true,
    infinite: false, //
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  useEffect(() => {
    async function fetchVirtualCardData() {
      try {
        await setIsloading(true);

        const res = await api.get('/card/vc/all');

        const myRes = res.data?.data?.data || []; // Use empty array if undefined

        // Filter cards where providerName is not 'Stpe'
        const filteredArray = myRes.filter(
          card => card.providerName !== 'Stripe',
        );

        setMyCardD(filteredArray);
        console.log(filteredArray);
        // Update the currentId based on the first card in the filtered array
        if (filteredArray.length > 0) {
          setCurrentId(filteredArray[0].cardId);
        }

        setIsloading(false);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching virtual card data:', error);
        setIsloading(false);
      }
    }

    fetchVirtualCardData();
  }, []);

  const dispatch = useDispatch();

  var spinCardModal = () => {
    (props.userdata?.country !== 'Ghana' &&
      props.userdata?.verifiedKyc === true) ||
    (props.userdata?.country !== 'Ghana' &&
      props.userdata?.verifiedKyc === 'APPROVED') ||
    (props.userdata?.country == 'Ghana' &&
      props.userdata?.kyclevel == 'Level2') ||
    (props.userdata?.country == 'Ghana' && props.userdata?.kyclevel == 'Level3')
      ? // ? dispatch(openVirtual('mono'))
        setShowModal(true)
      : dispatch(openUpdateBox());
  };
  // eslint-disable-next-line
  const modifyState = value => setState({ ...state, ...value });

  const [state, setState] = useState({
    virtualCardModal: true,
  });

  const [currentView, setCurrentView] = useState(1);

  const handleFundButClick = () => {
    setCurrentView(2);
  };
  const handleRevConClick = () => {
    setCurrentView(3);
  };

  const myData = [
    // {
    //   currencyFlag:
    //     'https://cdn.pixabay.com/photo/2012/04/10/16/22/united-26177__480.png',
    //   currency: 'USD',
    // },
    {
      currencyFlag: 'https://flagcdn.com/ng.svg',
      currency: 'NGN',
    },
    {
      currencyFlag: '',
      currency: 'GHC',
    },
  ];

  const [selected, setSelected] = useState({});

  const currencies = myData;

  const [rate, setRate] = useState(null);

  const [modal, setModal] = useState({
    value: false,
    currency: '',
    action: 'liquidate',
  });

  useEffect(() => {
    // settIsLoading(true);
    const fetchpriceData = async () => {
      try {
        const response = await api.get(
          `/vesti-fx/lists?currency=${
            selected?.currency == 'Others' ? 'USD' : selected?.currency
          }`,
        );
        setRate(response.data?.cardRate / 100);
        // settIsLoading(false);
      } catch (error) {
        // settIsLoading(false);
        console.log(error);
      }
    };

    fetchpriceData();
  }, [selected.currency]);

  useEffect(() => {
    setSelected(myData[0]);
  }, []);

  const handleCurrencyChange = selectedCurrency => {
    // setSelected(selectedCurrency);
  };

  const toWholeCurrency = num => {
    const value = num / 100;

    const formattedValue = value.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formattedValue;
  };

  return (
    <section>
      <SimpleCancelmodal onClick={() => setShowModal(false)} open={showModal}>
        <Ordercard
          authData={props.userData}
          closeModal={() => setShowModal(false)}
          cb={refetch}
          refetch={refetch}
          wallet={allWallet}
          currency={selected?.currency ? selected?.currency : 'NGN'}
          mycards={mycardD}
        />
      </SimpleCancelmodal>

      <SimpleCancelmodal
        onClick={() => setFundCardModal(false)}
        open={fundCardModal}
      >
        <div>
          <Titlesubtitle
            title="Review Transaction"
            subtitle="Please input your 4-Digit pin to finalize this transaction"
          />

          <div className="reviewTransDiv">
            <p className="reviewTransP">$100</p>
            <div className="reviewTrans">
              <p className="reviewTransD">You Recieve</p>
              <p className="reviewTransD">$100</p>
            </div>

            <div className="reviewTrans">
              <p className="reviewTransD"> Processing Fee</p>
              <p className="reviewTransD">#2,065</p>
            </div>

            <div className="reviewTrans">
              <p className="reviewTransD"> Amount in NGN</p>
              <p className="reviewTransD">#149,565</p>
            </div>
          </div>

          <div className="dotDiv">
            <img src={vcardDot} alt="" className="vcardDot" />
            <img src={vcardDot} alt="" className="vcardDot" />
            <img src={vcardDot} alt="" className="vcardDot" />
            <img src={vcardDot} alt="" className="vcardDot" />
          </div>

          <div className="reTranbutDiv">
            <p onClick={handleFundModal}>Fund Card</p>
          </div>
        </div>
      </SimpleCancelmodal>

      <SimpleCancelmodal
        onClick={() => setFundSelectedCard(false)}
        open={fundselectedCard}
      >
        <Fundcard
          name={mycardD[active]?.providerName}
          cardId={mycardD[active]?.cardId}
          cardStatus={mycardD[active]?.cardStatus}
          closeModal={() => {
            setFundSelectedCard(false);
          }}
          balance={parseFloat(mycardD[active]?.balance)}
          cb={() => mycardD[active] && refetch()}
          title={'Fund'}
          // cb={() => userCards[active].providerName === 'Mono' && refetch()}
          rate={rate}
          action={modal.action}
          wallet={allWallet}
          currency={selected?.currency ? selected?.currency : 'NGN'}
        />
      </SimpleCancelmodal>

      <SimpleCancelmodal
        onClick={() => setShowCardDelModal(false)}
        open={ShowcardDelModal}
      >
        <p className="cardDetH"> Card Details</p>

        <div className="carddetM">
          <Carddetails data={mycardD[active]} userdata={props.userData} />
        </div>
      </SimpleCancelmodal>

      <SimpleCancelmodal
        onClick={() => setFreezeModal(false)}
        open={freezeModal}
      >
        <div className="FreezeCardDiv">
          <img src={freeze} alt="" className="freezeImg" />
          <p className="freezePF">Freeze Card?</p>
          <p className="freezePS">Are you sure you want to freeze this card?</p>
          <div className="freezeButDiv">
            <p className="freezeCancel" style={{ backgroundColor: '#F2F4F7' }}>
              Cancel
            </p>
            <p className="freezeYes">Yes, Freeze</p>
          </div>
        </div>
      </SimpleCancelmodal>

      {isloading ? (
        <Loader />
      ) : mycardD?.length < 1 ? (
        <div className="mycard__empty">
          <Empty
            title="Create Virtual Card"
            subtitle={
              <div style={{ color: '#000000', fontSize: '16px' }}>
                Instantly create a Vesti Virtual Dollar Card to make payments
                online and shop globally.
                <br />
                <span style={{ color: '#2B5219' }}>
                  Available to Africans only
                </span>
              </div>
            }
            click={() => spinCardModal()}
            // click={()=>{setShowModal(true)}}
            name="Create Virtual Card"
            type="normal"
          />
        </div>
      ) : (
        <>
          {/* Virtual cards display section */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // marginRight: '14%',
            }}
          >
            <section className="vcardSection  ">
              <Tooltip placement="right" title="Create Card">
                <div
                  className="createVirtualCard"
                  style={{ zIndex: '20' }}
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <path
                      d="M15 2.5C8.1125 2.5 2.5 8.1125 2.5 15C2.5 21.8875 8.1125 27.5 15 27.5C21.8875 27.5 27.5 21.8875 27.5 15C27.5 8.1125 21.8875 2.5 15 2.5ZM20 15.9375H15.9375V20C15.9375 20.5125 15.5125 20.9375 15 20.9375C14.4875 20.9375 14.0625 20.5125 14.0625 20V15.9375H10C9.4875 15.9375 9.0625 15.5125 9.0625 15C9.0625 14.4875 9.4875 14.0625 10 14.0625H14.0625V10C14.0625 9.4875 14.4875 9.0625 15 9.0625C15.5125 9.0625 15.9375 9.4875 15.9375 10V14.0625H20C20.5125 14.0625 20.9375 14.4875 20.9375 15C20.9375 15.5125 20.5125 15.9375 20 15.9375Z"
                      fill="#518C36"
                    />
                  </svg>
                </div>
              </Tooltip>

              {mycardD?.length > 1 && active !== 0 ? (
                <img
                  className="nextPrevButtondesktop"
                  src={larrow}
                  alt="left"
                  onClick={() =>
                    active === 0 ? setActive(0) : setActive(active - 1)
                  }
                />
              ) : (
                <img
                  className="nextPrevButtondesktop"
                  style={{ cursor: 'not-allowed' }}
                  src={larrow}
                  alt="left"
                  onClick={() =>
                    active === 0 ? setActive(0) : setActive(active - 1)
                  }
                />
              )}

              <div className="slidercontCardRevamp">
                <div className="nextPrevButtonMobile">
                  {mycardD?.length > 1 && (
                    <img
                      src={larrow}
                      alt="left"
                      onClick={() =>
                        active === 0 ? setActive(0) : setActive(active - 1)
                      }
                    />
                  )}
                  {mycardD?.length > 1 && (
                    <img
                      src={rarrow}
                      alt="reft"
                      onClick={() =>
                        active === mycardD.length - 1
                          ? setActive(0)
                          : setActive(active + 1)
                      }
                    />
                  )}
                </div>

                <Slider {...lesscardsettings}>
                  {mycardD ? (
                    // <div className="flex items-center relative ">
                    <Cards
                      cdata={mycardD}
                      userData={props.userData}
                      active={active}
                      setActive={setActive}
                    />
                  ) : (
                    // </div>
                    <></>
                  )}
                </Slider>
                <div className="cardButOpt ">
                  <CardFeatureSingleCont
                    img={fundCard}
                    name={'Fund Card'}
                    click={() => {
                      setFundSelectedCard(true);
                    }}
                  />
                  <CardFeatureSingleCont
                    img={showDet}
                    name={'Show Details'}
                    click={() => {
                      setShowCardDelModal(true);
                    }}
                  />
                  {/* <CardFeatureSingleCont
                    img={freeze}
                    name={'Freeze'}
                    click={() => {
                      setFreezeModal(true);
                    }}
                  /> */}
                </div>
              </div>
              {mycardD?.length > 1 && active !== mycardD.length - 1 ? (
                <img
                  className="nextPrevButtondesktop"
                  src={rarrow}
                  alt="reft"
                  onClick={() =>
                    active === mycardD.length - 1
                      ? setActive(0)
                      : setActive(active + 1)
                  }
                />
              ) : (
                <img
                  className="nextPrevButtondesktop"
                  style={{ cursor: 'not-allowed' }}
                  src={rarrow}
                  alt="reft"
                  onClick={() =>
                    active === mycardD.length - 1
                      ? setActive(0)
                      : setActive(active + 1)
                  }
                />
              )}
            </section>
          </div>

          {/* cardbottom */}

          {/* Card Transaction Details Table section*/}
          <section className="revampTransSection">
            <VCardsTransactions id={mycardD[active]?.cardId} />
          </section>
        </>
      )}
    </section>
  );
};

const mapStateToProps = ({ auth, domore }) => {
  const { userData } = auth;

  const { openVirtual } = domore;
  return {
    userData,
    openVirtual,
  };
};
const mapDispatchToProps = {
  openUpdateBox,
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualcardPage);

const CardFeatureSingleCont = ({ img, name, click }) => {
  return (
    <div
      className="cardButtomDiv"
      onClick={() => {
        click();
      }}
    >
      <img src={img} alt="action svg" />
      <p className="cardButtomDivP">{name}</p>
    </div>
  );
};
