/* eslint-disable */

import React, { useState } from 'react';
import { Modal } from 'antd';
import './accordion.scss';
import './kycupload.scss';
import { Success } from 'components/common/success/success';
import Inputfloat from 'components/common/inputs/inputfloat';
import { Singleselect } from 'components/common/inputs/singleselect';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import api from 'appRedux/api';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
} from 'appRedux/actions/Common';
import verone from '../../assets/verone.svg';
import successnew from '../../assets/successnew.svg';
// import pending from "../../assets/pending.svg";
import { errorMessage } from 'helpers/utils';
import errorsvg from '../../assets/errorsvg.svg';
import Accordion from './accordion';
import caretup from '../../assets/caretup.svg';
import caretdown from '../../assets/caretdown.svg';
import circle from '../../assets/circle-check.svg';
import greyinfo from '../../assets/greyinfo.svg';
import { Smallinfo } from 'components/common/shortinfo/shortinfo';
import ModernDatepicker from 'react-modern-datepicker';
import { Platformbutton } from 'components/common/button/button';

function KycLv1(props) {
  const [modal, showModal] = useState(false);

  var closeModal = () => {
    showModal(false);
  };

  const handleKyc = () => {
    showModal(true);
  };
  return (
    <div>
      <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
        open={modal}
        onCancel={() => closeModal()}
        destroyOnClose
        footer=""
        centered={true}
        okButtonProps={{ style: { display: 'none' } }}
        styles={{
          mask: {
            background: 'rgba(103, 169, 72, 0.2)',
            backdropFilter: 'blur(4px)',
          },
        }}
      >
        <Kyc1
          onClick={closeModal}
          state={props.state}
          refetch={props.refetch}
          userdata={props.userdata}
        />
      </Modal>
      {/* {console.log(props)} */}
      {(props.userdata.country === 'Nigeria' ||
        props.userdata.country === 'NG') && (
        <Accordion
          caretdown={caretdown}
          caretup={caretup}
          handleKyc={handleKyc}
          country={props.userdata.country}
          title="Level 1 Verification"
          subtitle="Acceptable verification numbers"
          subtext={'You can select from our variety of documents options'}
          tinytext={'1'}
          number1={'International Passport Number,'}
          number2={' Virtual National Identity Number'}
          number4={'or Bank Verification Number'}
          children={
            <>
              <div className="side-inner">
                <img src={circle} alt="" />
                <li>Instant Withdrawal and Transfer limits of ₦100,000.</li>
              </div>
              <div className="side-inner">
                <img src={circle} alt="" />
                <li>Daily Withdrawal and Transfer limits of ₦500,000.</li>
              </div>
            </>
          }
          image={verone}
          buttontext={'Start L1 Verification Process'}
        />
      )}
      {props.userdata.country === 'Ghana' && (
        <Accordion
          caretdown={caretdown}
          caretup={caretup}
          handleKyc={handleKyc}
          title="Level 1 Verification"
          subtitle="Acceptable verification numbers"
          subtext={'You can select from our variety of documents options'}
          tinytext={'1'}
          number1={'International Passport Number'}
          number2={' or SSNIT'}
          country={props.userdata.country}
          // number3={'Bank Verification Number'}
          children={
            <>
              <div className="side-inner">
                <img src={circle} alt="" />
                <li>
                  Kindly proceed to complete level 2 KYC immediately to unlock
                  transaction capabilities.
                </li>
              </div>
              {/* <div className="side-inner">
                <img src={circle} alt="" />
                <li>Instant Withdrawal and Transfer limits of GH¢100,000.</li>
              </div>
              <div className="side-inner">
                <img src={circle} alt="" />
                <li>Daily Withdrawal and Transfer limits of GH¢500,000.</li>
              </div> */}
            </>
          }
          image={verone}
          buttontext={'Start L1 Verification Process'}
        />
      )}
      {props.userdata.country === 'Kenya' && (
        <Accordion
          caretdown={caretdown}
          caretup={caretup}
          handleKyc={handleKyc}
          country={props.userdata.country}
          title="Level 1 Verification"
          subtitle="Acceptable verification numbers"
          subtext={'You can select from our variety of documents options'}
          tinytext={'1'}
          number1={'International Passport Number'}
          number2={' or Kenya National ID'}
          children={
            <>
              <div className="side-inner">
                <img src={circle} alt="" />
                <li>
                  Kindly proceed to complete level 2 KYC immediately to unlock
                  transaction capabilities.
                </li>
              </div>
              {/* <div className="side-inner">
                <img src={circle} alt="" />
                <li>Instant Withdrawal and Transfer limits of ₦100,000.</li>
              </div>
              <div className="side-inner">
                <img src={circle} alt="" />
                <li>Daily Withdrawal and Transfer limits of ₦500,000.</li>
              </div> */}
            </>
          }
          image={verone}
          buttontext={'Start L1 Verification Process'}
        />
      )}
    </div>
  );
}

export default KycLv1;

export const Kyc1 = props => {
  // console.log('KYC 1', props.userdata?.country);
  // console.log('KYC 1 props: ', props);
  // eslint-disable-next-line no-unused-var
  const [type, setType] = useState('');
  const [district, setDistrict] = useState('');
  const [step, setStep] = useState(2);
  const [state, setState] = useState({
    type: '',
    identityNumber: '',
    dob: '',
  });
  const [message, setMessage] = useState('');
  const [btn, setBtn] = useState({
    name: 'Submit',
    value: true,
  });

  var options = [
    {
      label: 'Passport',
      value: 'PASSPORT',
    },
    {
      label: 'BVN',
      value: 'BVN',
    },
    {
      label: 'vNIN',
      value: 'NIN',
    },
    // {
    //   label: 'Voters Card',
    //   value: 'VOTERS_CARD',
    // },
  ];
  var ghOptions = [
    {
      label: 'Passport',
      value: 'PASSPORT',
    },
    {
      label: 'SSNIT',
      value: 'SSNIT',
    },
  ];
  var keOptions = [
    {
      label: 'Passport',
      value: 'PASSPORT',
    },
    {
      label: 'Kenya National ID',
      value: 'KENYA_NATIONAL_ID',
    },
  ];
  var zmOptions = [
    {
      label: 'Zimbabwe National ID',
      value: 'ZIMBABWE_NATIONAL_ID',
    },
  ];

  var setTy = value => {
    // var value = e.target.value;
    setType(value);
    setState({ ...state, type: value });
  };

  var uploadKycno = () => {
    setBtn({ ...btn, value: true, name: 'Validatiing...' });
    var statedis = district
      ? district.label.includes('State')
        ? district.label.slice(0, -6)
        : district.label
      : '';
    var data =
      state.type.value === 'PASSPORT'
        ? {
            identityNumber: state.identityNumber,
            dob: state.dob.value,
            type: state.type.value,
            country:
              props.userdata.country === 'Nigeria' ||
              props.userdata.country === 'NG'
                ? 'NGN'
                : props.userdata.country === 'Ghana'
                ? 'GHS'
                : props.userdata.country === 'Kenya'
                ? 'KES'
                : '',
          }
        : state.type.value === 'VOTERS_CARD'
        ? {
            ...state,
            type: state.type.value,
            state: statedis,
            dob: state.dob.value,
            country:
              props.userdata.country === 'Nigeria' ||
              props.userdata.country === 'NG'
                ? 'NGN'
                : '',
          }
        : state.type.value === 'SSNIT'
        ? {
            identityNumber: state.identityNumber,
            dob: state.dob.value,
            type: state.type.value,
            country: 'GHS',
          }
        : state.type.value === 'ZIMBABWE_NATIONAL_ID'
        ? {
            identityNumber: state.identityNumber,
            dob: state.dob.value,
            type: state.type.value,
            country: 'ZMW',
          }
        : {
            identityNumber: state.identityNumber,
            dob: state.dob.value,
            type: state.type.value,
            country: 'NGN',
            // props.userdata?.country === 'Nigeria' ||
            // props.userData?.country === 'NG'
            //   ? 'NGN'
            //   : props.userdata?.country === 'Ghana'
            //   ? 'GHS'
            //   : props.userdata?.country === 'Kenya'
            //   ? 'KES'
            //   : '',
          };
    // console.log('KYC Data: ', data);
    api
      .post('/identity-verification/level-one', data)
      .then(res => {
        // console.log(res);
        openNotificationWithIcon(
          `${state.type.value} validation`,
          `${res.data.message}`,
          'success',
        );
        props.refetch();
        setStep(3);
      })
      .catch(error => {
        props.refetch();
        openNotificationWithIconErr(errorMessage(error), 'Error', 'error');
        setBtn({ ...btn, value: false, name: 'Submit' });
        setMessage(error.data?.message);
        setStep(4);
      });
  };

  // console.log('userdat', props.userdata);

  var _renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <Success
            image={successnew}
            title="Successful"
            subtitle={message}
            onClick={
              props.userData.haspendingTransfer?.status === 'PENDING'
                ? props.openClaim
                : () => props.onClick()
            }
            button="Okay, Thank you."
          />
        );
      case 2:
        return (
          <div className="kycupload-inner-container">
            <Titlesubtitle
              steps="Step 1 "
              title="Level 1 KYC Verification"
              subtitle="You are almost there, you can choose to fill this form now or later to complete your account creation."
            />
            <div className="kycupload-inner-container right">
              <>
                <div
                  style={{ position: 'relative', zIndex: '999', width: '100%' }}
                >
                  <ModernDatepicker
                    date={state.dob?.value}
                    className="dob"
                    format={'YYYY-MM-DD'}
                    showBorder
                    onChange={date =>
                      setState({
                        ...props.state,
                        dob: { ...state.dob, value: date },
                      })
                    }
                    placeholder={'Date of Birth'}
                    primaryColor={'#67A948'}
                  />
                </div>
              </>
              <span className="mt-4"></span>
              <Singleselect
                value={state.type}
                options={
                  props.userdata.country === 'Nigeria' ||
                  props.userdata.country === 'NG'
                    ? options
                    : props.userdata.country === 'Ghana'
                    ? ghOptions
                    : props.userdata.country === 'Kenya'
                    ? keOptions
                    : props.userdata.country === 'Zimbabwe'
                    ? zmOptions
                    : options
                }
                name="type"
                placeholder="KYC Document"
                onChange={setTy}
                //disabled={isValueSet}
              />
              <span className="mt-4"></span>
              {state.type && state.type?.value !== '' && (
                <Inputfloat
                  label={`${state.type.label} NUMBER`}
                  type="text"
                  value={state.identityNumber}
                  placeholder={`Enter Your ${
                    state.type ? state.type?.label : 'KYC'
                  } Number`}
                  onChange={e =>
                    setState({ ...state, identityNumber: e.target.value })
                  }
                />
              )}
              {state.type && state.type?.value === 'NIN' && (
                <>
                  <Smallinfo
                    image={greyinfo}
                    style={{ backgroundColor: 'green' }}
                    subject={
                      <p className="cont" style={{ color: '#2D2F30' }}>
                        The Virtual NIN (vNIN) is designed to replace the raw
                        11-digit NIN for everyday usage.
                        <br />
                        <br />
                        <p style={{ color: '#2D2F30' }}>
                          This can be generated using:
                          <ol style={{ fontSize: '1rem' }}>
                            <li
                              style={{
                                color: '#2D2F30',
                                paddingBottom: '0',
                                fontSize: '1rem',
                              }}
                            >
                              NIMC official mobile app
                            </li>
                            <li style={{ color: '#2D2F30', fontSize: '1rem' }}>
                              USSD -{' '}
                              <strong
                                className=""
                                style={{
                                  color: '#2D2F30',
                                  paddingBottom: '0',
                                  fontSize: '1rem',
                                }}
                              >
                                *346*3*Your NIN*696739#
                              </strong>
                            </li>
                          </ol>
                        </p>
                      </p>
                    }
                  />
                </>
              )}
              <span className="mt-2"></span>
              {state.type?.value === 'VOTERS_CARD' && (
                <Singleselect
                  value={district}
                  options={props.state}
                  name="state"
                  // disable={true}
                  onChange={value => setDistrict(value)}
                />
              )}
              <div className="updateprofile-btn-box mt-4">
                <button
                  className="save-changes"
                  disabled={
                    state.type &&
                    state.dob &&
                    state.identityNumber &&
                    btn.name === 'Submit'
                      ? false
                      : true
                  }
                  // disabled={btn.value}
                  onClick={uploadKycno}
                >
                  {btn.name}
                </button>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <Success
            image={successnew}
            title="Successful"
            subtitle={message}
            onClick={() => {
              props.onClick();
              props.refetch();
            }}
            button="Okay, Thank you."
          />
        );

      case 4:
        return (
          <Success
            image={errorsvg}
            type="error"
            title="Failed"
            subtitle={
              message ||
              'Your kyc doc failed our verification process, click button below to try again.'
            }
            button="Try Again"
            onClick={() => setStep(2)}
          />
        );
      default:
        return <>default</>;
    }
  };

  return <div className="kycupload-container">{_renderSteps()}</div>;
};

export const OnboardingKYC = props => {
  // console.log('KYC 1', props.userdata?.country);
  // console.log('KYC 1 props: ', props);
  // eslint-disable-next-line no-unused-var
  const [type, setType] = useState('');
  const [district, setDistrict] = useState('');
  const [step, setStep] = useState(1);
  const [state, setState] = useState({
    type: '',
    identityNumber: '',
    dob: '',
  });
  const [message, setMessage] = useState('');
  const [btn, setBtn] = useState({
    name: 'Submit',
    value: true,
  });

  var options = [
    {
      label: 'Passport',
      value: 'PASSPORT',
    },
    {
      label: 'BVN',
      value: 'BVN',
    },
    {
      label: 'vNIN',
      value: 'NIN',
    },
    // {
    //   label: 'Voters Card',
    //   value: 'VOTERS_CARD',
    // },
  ];
  var ghOptions = [
    {
      label: 'Passport',
      value: 'PASSPORT',
    },
    {
      label: 'SSNIT',
      value: 'SSNIT',
    },
  ];
  var keOptions = [
    {
      label: 'Passport',
      value: 'PASSPORT',
    },
    {
      label: 'Kenya National ID',
      value: 'KENYA_NATIONAL_ID',
    },
  ];
  var zmOptions = [
    {
      label: 'Zimbabwe National ID',
      value: 'ZIMBABWE_NATIONAL_ID',
    },
  ];

  var setTy = value => {
    // var value = e.target.value;
    setType(value);
    setState({ ...state, type: value });
  };

  var uploadKycno = async () => {
    setBtn({ ...btn, value: true, name: 'Validatiing...' });
    var statedis = district
      ? district.label.includes('State')
        ? district.label.slice(0, -6)
        : district.label
      : '';
    var data =
      state.type.value === 'PASSPORT'
        ? {
            identityNumber: state.identityNumber,
            dob: state.dob.value,
            type: state.type.value,
            country:
              props.country === 'Nigeria' || props.country === 'NG'
                ? 'NGN'
                : props.country === 'Ghana'
                ? 'GHS'
                : props.country === 'Kenya'
                ? 'KES'
                : '',
          }
        : state.type.value === 'VOTERS_CARD'
        ? {
            ...state,
            type: state.type.value,
            state: statedis,
            dob: state.dob.value,
            country:
              props.country === 'Nigeria' || props.country === 'NG'
                ? 'NGN'
                : '',
          }
        : state.type.value === 'SSNIT'
        ? {
            identityNumber: state.identityNumber,
            dob: state.dob.value,
            type: state.type.value,
            country: 'GHS',
          }
        : state.type.value === 'ZIMBABWE_NATIONAL_ID'
        ? {
            identityNumber: state.identityNumber,
            dob: state.dob.value,
            type: state.type.value,
            country: 'ZMW',
          }
        : {
            identityNumber: state.identityNumber,
            dob: state.dob.value,
            type: state.type.value,
            country: 'NGN',
            // props.userdata?.country === 'Nigeria' ||
            // props.userData?.country === 'NG'
            //   ? 'NGN'
            //   : props.userdata?.country === 'Ghana'
            //   ? 'GHS'
            //   : props.userdata?.country === 'Kenya'
            //   ? 'KES'
            //   : '',
          };
    // console.log('KYC Data: ', data);

    const token = localStorage.getItem('token'); // Replace with your token access method

    const headers = {
      Authorization: `Bearer ${token}`, // Include token in Authorization header
      'Content-Type': 'application/json', // Add Content-Type for JSON data
    };

    try {
      const response = await api.post(
        '/identity-verification/level-one',
        JSON.stringify(data),
      );
      openNotificationWithIcon(
        `${state.type.value} validation`,
        `${response.data?.message}`,
        'success',
      );
      // props.refetch();
      if (!props.vtitle) {
        props.setStep(5);
      }
      if (props.vtitle) {
        props.setStepfund(2);
      }
    } catch (error) {
      console.error('Error calculating interest:', error);
      if (props.vtitle) {
        openNotificationWithIconErr(errorMessage(error), 'Error', 'error');
        props.setStepfund(10);
      }
      if (!props.vtitle) {
        props.setStep(5);
      }
    }

    // try {
    //   const response = await fetch(
    //     'http://syca-app-backend.eba-pe3mzmfm.us-east-1.elasticbeanstalk.com/api/v1/identity-verification/level-one',
    //     {
    //       method: 'POST',
    //       headers,
    //       body: JSON.stringify(data),
    //     },
    //   );

    //   openNotificationWithIcon(
    //     `${state.type.value} validation`,
    //     `${response.data?.message}`,
    //     'success',
    //   );

    //   if (!props.vtitle) {
    //     props.setStep(4);
    //   }
    //   if (props.vtitle) {
    //     props.setStepfund(2);
    //   }
    // } catch (error) {
    //   if (props.vtitle) {
    //     openNotificationWithIconErr(errorMessage(error), 'Error', 'error');
    //     props.setStepfund(10);
    //   }
    //   if (!props.vtitle) {
    //     props.setStep(4);
    //   }
    // props.refetch();
    // openNotificationWithIconErr(errorMessage(error), 'Error', 'error');
    // setBtn({ ...btn, value: false, name: 'Submit' });
    // setMessage(error.data?.message);
    // }
  };

  // console.log('userdat', props.userdata);

  var _renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <div className="kycupload-inner-container" style={{ width: '100%' }}>
            <div
              className="kycupload-inner-container right"
              style={{ width: '100%' }}
            >
              <div
                style={{ position: 'relative', zIndex: '999', width: '100%' }}
              >
                <ModernDatepicker
                  date={state.dob?.value}
                  className="dob"
                  format={'YYYY-MM-DD'}
                  showBorder
                  onChange={date =>
                    setState({
                      ...props.state,
                      dob: { ...state.dob, value: date },
                    })
                  }
                  placeholder={'Date of Birth'}
                  primaryColor={'#67A948'}
                />
              </div>
              <div className="mt-4"></div>
              <Singleselect
                value={state.type}
                options={
                  props.country === 'Nigeria' || props.country === 'NG'
                    ? options
                    : props.country === 'Ghana'
                    ? ghOptions
                    : props.country === 'Kenya'
                    ? keOptions
                    : props.country === 'Zimbabwe'
                    ? zmOptions
                    : options
                }
                name="type"
                placeholder="KYC Document"
                onChange={setTy}
                //disabled={isValueSet}
              />
              <span className="mt-4"></span>
              {state.type && state.type?.value !== '' && (
                <Inputfloat
                  label={`${state.type.label} NUMBER`}
                  type="text"
                  value={state.identityNumber}
                  placeholder={`Enter Your ${
                    state.type ? state.type?.label : 'KYC'
                  } Number`}
                  onChange={e =>
                    setState({ ...state, identityNumber: e.target.value })
                  }
                />
              )}
              {state.type && state.type?.value === 'NIN' && (
                <>
                  <Smallinfo
                    image={greyinfo}
                    style={{ backgroundColor: 'green' }}
                    subject={
                      <p className="cont" style={{ color: '#2D2F30' }}>
                        The Virtual NIN (vNIN) is designed to replace the raw
                        11-digit NIN for everyday usage.
                        <br />
                        <br />
                        <p style={{ color: '#2D2F30' }}>
                          This can be generated using:
                          <ol style={{ fontSize: '1rem' }}>
                            <li
                              style={{
                                color: '#2D2F30',
                                paddingBottom: '0',
                                fontSize: '1rem',
                              }}
                            >
                              NIMC official mobile app
                            </li>
                            <li style={{ color: '#2D2F30', fontSize: '1rem' }}>
                              USSD -{' '}
                              <strong
                                className=""
                                style={{
                                  color: '#2D2F30',
                                  paddingBottom: '0',
                                  fontSize: '1rem',
                                }}
                              >
                                *346*3*Your NIN*696739#
                              </strong>
                            </li>
                          </ol>
                        </p>
                      </p>
                    }
                  />
                </>
              )}
              <span className="mt-2"></span>
              {state.type?.value === 'VOTERS_CARD' && (
                <Singleselect
                  value={district}
                  options={props.state}
                  name="state"
                  // disable={true}
                  onChange={value => setDistrict(value)}
                />
              )}

              <div className="mt-4" style={{ width: '100%' }}>
                <Platformbutton
                  type="normal"
                  name={'Verify'}
                  click={() => {
                    // props.setStep(4);
                    uploadKycno();
                    // uploadKycno();
                  }}
                  disabled={!state.type || !state.dob?.value ? true : false}
                />
              </div>
              {/* <div
                className="updateprofile-btn-box mt-4"
                style={{ width: '100%' }}
              >
                <button
                  className="save-changes"
                  style={{ width: '100%' }}
                  disabled={
                    state.type &&
                    state.dob &&
                    state.identityNumber &&
                    btn.name === 'Submit'
                      ? false
                      : true
                  }
                 
                  onClick={uploadKycno}
                >
                  {btn.name}
                </button>
              </div> */}
            </div>
          </div>
        );
      case 2:
        return (
          <Success
            image={successnew}
            title="Successful"
            subtitle={message}
            onClick={() => {
              props.onClick();
              props.refetch();
            }}
            button="Okay, Thank you."
          />
        );

      default:
        return <>default</>;
    }
  };

  return <div className="kycupload-container">{_renderSteps()}</div>;
};
