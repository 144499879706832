import React, { useState, useEffect } from 'react';
import Layout from 'components/common/DashboardLayout';
import { openUpdateBox } from 'appRedux/actions/update';
import { connect, useDispatch } from 'react-redux';

import { Vestirate } from 'components/common/vestirate/vestirate';
import VirtualcardPage from './virtualcardPage';
import PhysicalcardPage from './physicalcardPage';
import '../Cash/carddetails/carddetails.scss';
import './allcards.scss';
import { Simplemodal } from 'components/common/simplifiedmodal';
import { useUserData } from 'helpers/hooks';
import Ordercard from 'components/vesticards/selectcard';
import { PhysicalCard } from './physicalCard';
import api from 'appRedux/api';
import disabledB from 'assets/physicalCard/disabledBadge.svg';
import ClickedToogle from 'assets/physicalCard/clickedtoggle.svg';
import UnClickedToogle from 'assets/physicalCard/unclickedtoggle.svg';
import ActivateandDeactivate from './activateanddeactivate';
import { TourGuidemodal } from 'components/common/simplifiedmodal';
// import { openCardTourModal } from 'appRedux/actions/Tourguide';
import { useSelector } from 'react-redux';
import { IntroCardGuide } from 'TourGuide/CardTourGuide/Intro/intro';

// import { Smalltitle } from 'components/common/titlesubtitle/titlesubtitle';

const Allcards = props => {
  // console.log(document.referrer);

  // let isDocumentReferrer;

  // isDocumentReferrer = document.referrer;

  const [docref, setDocRef] = useState('');

  useEffect(() => {
    setDocRef(document.referrer);
  }, []);

  // console.log(document.referrer);

  // console.log('all Cards props:', props);
  // const [isactive, setIsActive] = useState(true)
  const { userData, refetch } = useUserData();
  // console.log('all Cards userData :', userData);
  const IsCardTrue = useSelector(state => state.Tourguide.openCardTourModal);

  const [virtualPage, setVirtualPage] = useState(true);
  const [physicalPage, setPhysicalPage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [requestGGcard, setRequestGGcard] = useState(false);
  const [physicalcardStatus, setPhysicalcardStatus] = useState('Pending');
  const [temporaryPin, setTemporaryPin] = useState(1234);
  const storedCurrency = localStorage.getItem('currency');
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [deactivated, setDeactivated] = useState(false);
  const [disabledView, setDisabledView] = useState(false);
  const [CardTour, setCardTour] = useState(IsCardTrue);

  // console.log(storedCurrency);

  useEffect(() => {
    const fetchstatus = async () => {
      try {
        const response = await api.get(`/card/pc/1/user`);
        // console.log(response);
        setPhysicalcardStatus(response.data?.data?.data[0]?.status);
        setTemporaryPin(response.data?.data?.data[0]?.tempResetPin);
      } catch (error) {
        console.log(error);
      }
    };
    fetchstatus();
    // eslint-disable-next-line
  }, []);

  const handleVirtualPage = () => {
    setVirtualPage(true);
    setPhysicalPage(false);
  };

  const dispatch = useDispatch();

  const handlePhysicalPage = () => {
    if (physicalcardStatus) {
      setVirtualPage(false);
      setPhysicalPage(true);
    } else if (
      (props.userdata?.country !== 'Ghana' &&
        props.userdata?.verifiedKyc === true) ||
      (props.userdata?.country !== 'Ghana' &&
        props.userdata?.verifiedKyc === 'APPROVED') ||
      (props.userdata?.country == 'Ghana' &&
        props.userdata?.kyclevel == 'Level2') ||
      (props.userdata?.country == 'Ghana' &&
        props.userdata?.kyclevel == 'Level3')
    ) {
      setRequestGGcard(true);
    } else {
      dispatch(openUpdateBox());
    }
  };

  useEffect(() => {
    setDeactivated(disabledView);
  }, [disabledView]);

  // const handleOpenShowmodal = () => {
  //   docref ? setShowModal(true) : setShowModal(showModal);
  //   setDocRef('');
  // };

  const handleCloseShowmodal = () => {
    setShowModal(false);
    setDocRef('');
  };

  // const handleActivateDeactivate = () => {
  //   setDeactivateModal(false);
  //   setDisabledView(true);
  // };

  var handleActivateDeactivate = () => {
    api
      .post('/card/pc/1/enable-and-disable-card')
      .then(res => {
        // console.log(res);
        if (disabledView) {
          setDisabledView(false);
        } else {
          setDisabledView(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <TourGuidemodal onClick={() => setCardTour(false)} open={CardTour}>
        <IntroCardGuide />
      </TourGuidemodal>

      <Simplemodal
        onClick={() => setDeactivateModal(false)}
        open={deactivateModal}
      >
        <ActivateandDeactivate
          handleActivateDeactivate={handleActivateDeactivate}
        />
      </Simplemodal>

      <Simplemodal
        onClick={() => handleCloseShowmodal()}
        // open={docref ? true : showModal}
        open={showModal}
      >
        <Ordercard
          // authData={props.authData}
          authData={userData}
          closeModal={() => setShowModal(false)}
          cb={refetch}
          refetch={refetch}
        />
      </Simplemodal>

      <Simplemodal onClick={() => setRequestGGcard(false)} open={requestGGcard}>
        {/* <div>Hello I'm GG card</div> */}
        <PhysicalCard setRequestGGcard={setRequestGGcard} />
      </Simplemodal>

      <Layout>
        <section className="yourcard px-2 " style={{ height: '100vh' }}>
          <p className="smallTitle__mobile">Your Cards</p>

          <div className="GGcardDisable">
            <div className="yourcard__toggle" style={{ alignItems: 'center' }}>
              <div className="flex gap-2 text-[16px] cards__tggle font-semibold">
                <p
                  className={`${
                    virtualPage
                      ? `bg-vesti-700 text-white`
                      : `bg-white text-vesti-901`
                  }`}
                  style={{
                    padding: '5px 10px 5px 10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={handleVirtualPage}
                >
                  Virtual Card(s)
                </p>

                <p
                  className={`cards__tggle__opt ${
                    physicalPage
                      ? `bg-vesti-700 text-white`
                      : `bg-white text-vesti-901`
                  }`}
                  style={{
                    padding: '5px 10px 5px 10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={handlePhysicalPage}
                >
                  Physical Card
                </p>
              </div>
              {/* && physicalcardStatus === 'Delivered' */}
            </div>

            <div>
              {physicalPage && physicalcardStatus === 'Delivered' ? (
                <div className="ActiveGGcardToggle">
                  {!deactivated ? (
                    <>
                      <div className="ActiveGGcardToggle__article">
                        <h1>Disable Card</h1>
                        <p>
                          Click on the toggle to temporarily disable your card.
                        </p>
                      </div>
                      <div>
                        <img
                          src={UnClickedToogle}
                          alt=""
                          style={{ cursor: 'pointer' }}
                          // onClick={() => {
                          //   setDeactivateModal(true);
                          // }}
                          onClick={handleActivateDeactivate}
                        />
                      </div>{' '}
                    </>
                  ) : (
                    <>
                      {' '}
                      <div className="ActiveGGcardToggle__article">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <h1
                            style={{
                              color: '#667085',
                              fontWeight: '500',
                              fontSize: '18px',
                            }}
                          >
                            Unblock Card
                          </h1>
                          <img
                            src={disabledB}
                            alt=""
                            style={{ marginTop: '-8px' }}
                          />
                        </div>
                        <p>Click on the toggle to unblock your card.</p>
                      </div>
                      <div>
                        <img
                          src={ClickedToogle}
                          alt=""
                          style={{ cursor: 'pointer' }}
                          onClick={handleActivateDeactivate}
                        />
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}

              <div>
                {virtualPage && (
                  <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
                    <div className="vestirateVc">
                      <Vestirate name="Checkbook" currency={storedCurrency} />
                    </div>
                    <div
                      className="createVirtualCard__mobile"
                      onClick={() => {
                        setShowModal(true);
                      }}
                      style={{ width: '60%' }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                      >
                        <path
                          d="M15 2.5C8.1125 2.5 2.5 8.1125 2.5 15C2.5 21.8875 8.1125 27.5 15 27.5C21.8875 27.5 27.5 21.8875 27.5 15C27.5 8.1125 21.8875 2.5 15 2.5ZM20 15.9375H15.9375V20C15.9375 20.5125 15.5125 20.9375 15 20.9375C14.4875 20.9375 14.0625 20.5125 14.0625 20V15.9375H10C9.4875 15.9375 9.0625 15.5125 9.0625 15C9.0625 14.4875 9.4875 14.0625 10 14.0625H14.0625V10C14.0625 9.4875 14.4875 9.0625 15 9.0625C15.5125 9.0625 15.9375 9.4875 15.9375 10V14.0625H20C20.5125 14.0625 20.9375 14.4875 20.9375 15C20.9375 15.5125 20.5125 15.9375 20 15.9375Z"
                          fill="#518C36"
                        />
                      </svg>
                      Create card
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {virtualPage && (
            <div>
              <VirtualcardPage
                // userData={props.userData}
                userData={userData}
                authData={props.authUser}
              />
            </div>
          )}

          {physicalPage && (
            <div>
              <PhysicalcardPage
                physicalcardStatus={physicalcardStatus}
                temporaryPin={temporaryPin}
              />
            </div>
          )}
        </section>
      </Layout>
    </>
  );
};

const mapStateToProps = ({ auth, domore }) => {
  const { userData } = auth;

  const { openVirtual } = domore;
  return {
    userData,
    openVirtual,
  };
};
const mapDispatchToProps = {
  openUpdateBox,
};

export default connect(mapStateToProps, mapDispatchToProps)(Allcards);

// export default Allcards;
