import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { showAuthLoader } from 'appRedux/actions/Common/';
import '../Admin.css';
import { fetchTransactions } from 'appRedux/actions/transactions';
import { getProfile, getUser } from 'appRedux/actions/auth';
import Loader from 'components/Loader';
import { redirectTransfer } from 'routes/RedirectRoutes';
import Layout from 'components/common/DashboardLayout';
import { addCashToWallet } from './actions/index';
import VirtualCardModal from './VirtualCardModal';
import { useFounders } from 'helpers/hooks';
import Ordercard from 'components/vesticards/selectcard';
import Depositwallet, { Providusaccount } from 'components/deposit/deposit';
import api from 'appRedux/api';
import { openUpdateBox } from 'appRedux/actions/update';
import Domore from './Domore/domore';
import { IntroBankGuide } from 'TourGuide/BankTourGuide/Intro/intro';

// import whatsnewbutton from 'assets/pathways/whatsnewbutton.png';
// import { FaArrowDown, FaArrowUp, FaTimes } from 'react-icons/fa';
// import { Domore } from 'components/bank/domore/domore';
import './cash.scss';
import { useQuery } from 'react-query';
import {
  openNotificationWithIcon,
  openNotificationWithIconErr,
  openReminder,
} from '../../../appRedux/actions/Common';
import { Simplemodal } from '../../../components/common/simplifiedmodal';
import Fundcard from './carddetails/fundcard';
import {
  closeVirtual,
  openVirtual,
  closeAccWait,
  // openWaitlist,
  closeWaitlist,
  handlePartners as handlePartner,
} from 'appRedux/actions/domore';
import { Accountwaitlist } from 'components/bank/accountwaitlist/accountwaitlist';
import { Depositusdwallet } from 'components/deposit/depositusdwallet';
import { Withaddbank } from 'components/bank/addbank/addbank';
import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Fileupload } from 'components/common/fileupload/fileupload';
import { saveAs } from 'file-saver';
// import { Newfeature } from 'components/bank/newfeature/newfeature';
import Createstripe from 'components/vesticards/createstripe';
import { Comingsoon } from 'components/common/comingsoon/comingsoon';
import {
  addMoneyAction,
  sendMoneyAction,
  walletAction,
  walletUsdAction,
} from 'appRedux/actions/wallets';
import Financialtrans from 'components/bank/financialaccount/transactions';
import Ngnwithdrawal from 'components/bank/ngnwithdrawal/ngnwithdrawal';
import Fbotransactions from 'components/bank/fbo/fbotransactions';
import { Wallets } from 'components/bank/balancecard/wallets';
import { fetchAllCards } from 'appRedux/actions/cards';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { Transtable } from 'components/bank/transtable';
// import { Payments } from 'components/bank/actionpayment/payment';
import { globalGengCardStatus } from 'appRedux/actions/waitlist';
import {
  // nameInUppercase,
  __renderWalletTitle,
  getCurrencyCode,
} from 'helpers/utils';
import _ from 'lodash';
import { fetchUserData } from 'appRedux/actions/profile';
import { navigate } from '@reach/router';
import { Stepback } from 'components/common/stepback/stepback';
import Addmoney from 'components/bank/transactions/addmoney';
import Sendmoney from 'components/bank/transactions/sendmoney';
// import { Selectcurrency } from 'components/bank/selectcurrency';
import Addwallet from 'components/bank/addwallet';
import { supportedWallets } from 'helpers/data';
import { Platformbutton } from 'components/common/button/button';
import { WALLET_ACTION } from 'appRedux/constants';

// eslint-disable-next-line

import { Partnership } from 'components/partnership';
// import { Smallempty } from 'components/common/empty/empty';
import { Payment } from 'components/bank/payments/payment';
import { Quickfeature } from 'components/bank/quickfeature/quickfeature';

import pathwaywhatsnew from 'assets/pathways/video/pathwaywhatnew.mp4';
import multicurrencywhatnew from 'assets/pathways/video/multicurrencywhatnew.mp4';
import feedswhatnew from 'assets/pathways/video/feedswhatnew.mp4';

import Vloader from 'assets/vestiLoader/vestiLoader.gif';
import {
  Largemodal,
  TourGuidemodal,
} from '../../../components/common/simplifiedmodal';
import SeeallquickAction from 'components/bank/quickfeature/seeallquickAction';
import { Smalltitle } from 'components/common/titlesubtitle/titlesubtitle';
import TourGuide from 'TourGuide/TourGuide';
import { FlightTourGuide } from 'TourGuide/FlightTourGuide/flightTour';
import MiaiAvatar from 'assets/miai/Miai.svg';
import Safe from 'assets/safelock.svg';
import { ImCheckboxChecked } from 'react-icons/im';
import h1bIcon from 'assets/popupModalIcons/H1bpopup.svg';
import { Newprompts } from 'components/common/prompt/prompt';
import NewSavings from '../Savings/NewSavings';
// import TourGuideModal from 'TourGuide/Modal/BankModal';
// eslint-disable-next-line
const useFetchUser = () => {
  const { isLoading, data: userData, refetch } = useQuery(
    'userData',
    // {refetchOnWindowFocus: false,enabled: false},
    async () =>
      await api.get('/authentication/profile').then(res => res.data.data),
  );

  return { isLoading, userData, refetch };
};

const Cash = props => {
  // console.log('cash-props: ', props);

  //  logged in user data from store
  const userData = useSelector(
    state => (state.auth.userData ? state.auth.userData : []),
    _.isEqual,
  );

  //  console.log('Wallets:: ', userWallets);

  const [state, setState] = useState({
    modal: false,
    withdrawalModal: false,
    addbank: 'withdrawal',
    virtualCardModal: false,
    currency: 'USD_CENTS',
    numInput: '',
  });

  // eslint-disable-next-line
  const handleSeeAllWhatClick = () => {
    setWhatPopupOpen(true);
  };

  // eslint-disable-next-line
  const handleCloseWhatPopup = () => {
    setWhatPopupOpen(false);
  };

  // eslint-disable-next-line
  const [showModal, setShowModal] = useState(false);
  const [currency, setCurrency] = useState(true);

  // eslint-disable-next-line
  const toggleCurrency = () => setCurrency(!currency);
  const [rate, setRate] = useState();
  const [account, setAccount] = useState(false);
  const [fundmodal, setFundmodal] = useState({
    value: false,
    id: '',
    name: '',
    currency,
  });
  const [usdmodal, setUsdmodal] = useState(false);
  const [upload, setUpload] = useState(false);
  const [bankform, setBankform] = useState({
    document: '',
    name: '',
    btn: '',
  });
  // eslint-disable-next-line
  const [gstatus, setGstatus] = useState();
  // eslint-disable-next-line
  const [activeAccount, setActiveAccount] = useState(0);
  const [savingsOpen, setSavingsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [seeallactions, setseeallActions] = useState(false);
  const modifyState = value => setState({ ...state, ...value });

  var accountRequest = () => {
    api
      .post('/bank/request-account-number', {
        email: userData.email,
      })
      .then(res => {
        openNotificationWithIcon(
          res.data.message,
          'Account request successful',
          'success',
        );
      })
      .catch(err => {
        openNotificationWithIconErr(
          err.data.message,
          'Account request failed',
          'error',
        );
      });
  };
  const dispatch = useDispatch();

  var openUpdateModal = () => {
    dispatch(openUpdateBox());
    // dispatch(openReminder(true));
  };

  const { search } = useLocation();

  const allWallet = useSelector(state => state.wallets?.wallets, _.isEqual);

  const alternateDefaultCurrency = getCurrencyCode(
    userData?.country ? userData?.country : userData?.address?.country,
  );
  const defaultCurrency = allWallet?.find(item => item.default === true);

  const finalCurrency = defaultCurrency
    ? defaultCurrency
    : allWallet?.find(item => item.currency === alternateDefaultCurrency);

  const filteredData = allWallet?.filter(
    item =>
      item.bank &&
      item.bank.hasOwnProperty('bankName') &&
      item.bank.hasOwnProperty('number'),
  );

  const refetch = () => props.getUser();

  // this has to do with the wallet kicking in after transaction when a refetch happens

  const wallet = search ? queryString.parse(search) : { wallet: 'bank' };

  // console.log("Cash Wallet",wallet)

  const { balance, foundersRefetch } = useFounders(
    userData?.id,
    userData?.stripeAccountStatus,
  );

  // useEffect(() => {
  //   props.fetchAllCards()
  //   props.fetchTransactions();
  //   // eslint-disable-next-line
  // }, [userData.hasVirtualCard, userData.monoCardHolderId]);

  const toggle = () => {
    modifyState({ modal: !state.modal });
  };
  const closeUsdmodal = () => {
    setUsdmodal(false);
  };
  const onInputChange = value => {
    modifyState({ numInput: value });
  };

  const handleCurrencyChange = value => {
    modifyState({ currency: value?.value });
  };

  var handleModal = () => {
    setState({
      ...state,
      modal: false,
      currency: 'USD_CENTS',
      numInput: '',
    });
  };

  const handleAddWallet = () => {
    const { numInput, currency } = state;
    props.addCashToWallet(numInput, currency, handleModal, refetch);
    props.showAuthLoader();
    props.getProfile();
    // refetch()
    // setTimeout(()=> {
    //   setState({...state, modal:false, currency:'USD_CENTS', numInput:''})
    // }, 4000)
  };

  // eslint-disable-next-line
  const showWithdrawalModal = () => {
    modifyState({
      withdrawalModal: true,
      addbank: 'withdrawal',
    });
  };
  const closeWithopenAddbank = () => {
    modifyState({ addbank: 'addbank' });
  };
  const openAddbankcloseAddbank = () => {
    modifyState({ addbank: 'withdrawal' });
  };
  const closeWithdrawalModal = () => {
    modifyState({
      withdrawalModal: false,
      addbank: 'withdrawal',
    });
  };

  var handleDoc = e => {
    var value = e.currentTarget.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(e.currentTarget.files[0]);
    reader.onload = function() {
      setBankform({
        ...bankform,
        document: reader.result,
        name: value,
      });
    };
  };

  var uploadDocument = () => {
    setBankform({ ...bankform, btn: 'uploading...' });
    var formdata = new FormData();
    formdata.append('file', bankform.name);
    // console.log(bankform.name)
    api
      .post('pof/upload/account-form', formdata)
      .then(res => {
        openNotificationWithIcon(res.data.message, 'Bank Form', 'success');
        //  setBankform({...bankform, btn:''})
        refetch();
        setBankform({
          ...bankform,
          document: '',
          name: '',
          btn: '',
        });
        setUpload(false);
      })
      .catch(err => {
        openNotificationWithIconErr(err.data.message, 'Bank Form', 'error');
        refetch();
        setBankform({ ...bankform, btn: '' });
      });
  };

  const saveFile = () => {
    saveAs(
      'https://res.cloudinary.com/wevesti/raw/upload/v1651401205/lur8vpchxo6wphqwvvsl',
      'bankformvesti.pdf',
    );
  };
  const { modal, withdrawalModal, virtualCardModal, addbank } = state;

  // eslint-disable-next-line
  const { loading, transactionsData } = props;
  // const { userData } = useUserData();
  // var myData = localStorage.getItem('userData')
  var dt = {
    cardColor: '',
    billingAddress: '',
    postalCode: '',
    country: '',
    cardPin: '',
  };

  // add and send money
  var handleAddMoney = value => {
    (userData?.country !== 'Ghana' && userData?.verifiedKyc === true) ||
    (userData?.country !== 'Ghana' && userData?.verifiedKyc === 'APPROVED') ||
    (userData?.country == 'Ghana' && userData?.kyclevel == 'Level2') ||
    (userData?.country == 'Ghana' && userData?.kyclevel == 'Level3')
      ? dispatch(addMoneyAction({ addMoney: value }))
      : dispatch(addMoneyAction({ addMoney: value }));
  };

  var handleSendMoney = value => {
    (userData?.country !== 'Ghana' && userData?.verifiedKyc === true) ||
    (userData?.country !== 'Ghana' && userData?.verifiedKyc === 'APPROVED') ||
    (userData?.country == 'Ghana' && userData?.kyclevel == 'Level2') ||
    (userData?.country == 'Ghana' && userData?.kyclevel == 'Level3')
      ? dispatch(sendMoneyAction({ sendMoney: value }))
      : // : openUpdateModal();
        // : alert("Complete your KYC");
        dispatch(openReminder(true));
  };

  // console.log('Cash props: ', props);
  useEffect(() => {
    if (userData?.length !== 0 && userData?.email !== '') {
      const country = userData?.country
        ? userData?.country
        : userData?.address?.country;
      // console.log(country);
      async function fetchData() {
        try {
          const response = await api.get(
            `/vesti-fx/lists?currency=${getCurrencyCode(country)}`,
          );
          // console.log(response);
          setRate(response.data);
        } catch (err) {
          // Handle the error
          console.error(err);
        }
      }

      fetchData();
    }

    // eslint-disable-next-line
  }, [props.currency, props.userData]); // Include any dependencies from props or state that are used inside the effect

  // console.log('User Data: ', userData);

  useEffect(() => {
    globalGengCardStatus(setGstatus);
  }, []);

  const whatsnewData = [
    {
      id: 1,
      title: 'Multi-currency Wallet',
      description:
        "Whether you're a traveler or a global entrepreneur, this cutting-edge feature is your passport to borderless banking. It empowers you to store, exchange, and transact in multiple currencies from the comfort of our single, user-friendly platform.",
      image: multicurrencywhatnew,
      button: 'Create a new wallet',
      link: '/bank',
    },

    {
      id: 2,
      title: 'Migration Pathways',
      description:
        'Discover your journey to a new life with Migration Pathways on the Vesti app. Explore a range of visa options and navigate the migration process effortlessly through detailed step-by-step guidance. Your dream destination is now within reach.',
      image: pathwaywhatsnew,
      button: 'Create a Pathway',
      link: '/pathways',
    },

    {
      id: 3,
      title: 'Feeds',
      description:
        'Embarking on a journey of immigration is a transformative experience. Our Growth Community is designed to be your steadfast companion throughout this exciting voyage, offering unwavering support, valuable resources, and a thriving network that propels you towards a successful and fulfilling immigration process.',
      image: feedswhatnew,
      button: 'Go to Feeds',
      link: '/feeds',
    },
  ];

  // console.log('Cash Modal: ', props.openVirtual);

  // eslint-disable-next-line
  const [activeItemId, setActiveItemId] = useState(1); // Add this line
  // eslint-disable-next-line
  const [isWhatPopupOpen, setWhatPopupOpen] = useState(false);
  // eslint-disable-next-line
  const activeItem = whatsnewData.find(item => item.id === activeItemId);

  // new vesti loader
  const [VestiLoader, setVesttiLoader] = useState(true);
  const [bankTourmodal, setBankTourmodal] = useState(false);

  setTimeout(() => {
    setVesttiLoader(false);
  }, 3000);

  // eslint-disable-next-line
  const handleCreateWalletClick = () => {
    setWhatPopupOpen(false);

    dispatch({
      type: WALLET_ACTION,
      payload: { addWallet: true },
    });
  };

  useEffect(() => {
    if (!userData.safeLockAndMiaiEventsNotification) {
      const timeoutId = setTimeout(() => {
        setOpen(true);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [userData.safeLockAndMiaiEventsNotification]);

  const handleWaitList = async () => {
    setOpen(false);
    const data = {
      key: 'safeLockAndMiaiEventsNotification',
      value: true,
    };

    try {
      const res = await api.post('/customer/notification', data);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const [isFlightTour, setFlightTour] = useState(false);

  const [tourGuide, setTourGuide] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isMiaiModal, setIsMiaiModal] = useState(false);
  const [isH1bModal, setIsH1bModal] = useState(true);

  useEffect(() => {
    const notification = userData?.notification?.miaiNotification;

    if (notification === false) {
      setIsMiaiModal(true);
    } else if (notification === undefined) {
      setIsMiaiModal(false);
    } else if (notification) {
      setIsMiaiModal(false);
    }
  }, [userData]);

  useEffect(() => {
    const notification = userData?.notification?.h1bNotification;
    const miainotification = userData?.notification?.miaiNotification;

    if (notification === false && miainotification === true) {
      setIsH1bModal(true);
    } else if (notification) {
      setIsH1bModal(false);
    } else if (notification === undefined) {
      setIsH1bModal(true);
    }
  }, [userData]);

  const [isChecked, setIsChecked] = useState(false);

  // Function to handle checkbox change

  const handleMiaiPopUp = async () => {
    setIsChecked(!isChecked);
    // var url = 'https://api.wevesti.com/api/v1/customer/notification';

    // const formData = new FormData();

    // formData.append('miaiNotification', true);

    const payload = {
      key: 'miaiNotification',
      value: true,
    };

    api
      .patch('customer/notification', payload)
      .then(res => {
        setIsMiaiModal(false);
      })
      .catch(err => {
        console.error('Error:', err);
        setIsMiaiModal(false);
      });
  };

  const handleh1BPopUp = async () => {
    setIsChecked(!isChecked);

    const payload = {
      key: 'h1bNotification',
      value: true,
    };

    api
      .patch('customer/notification', payload)
      .then(res => {
        setIsH1bModal(false);
      })
      .catch(err => {
        console.error('Error:', err);
        setIsH1bModal(false);
      });
  };

  const handleSavingModalStep = () => {
    if (
      (userData?.country !== 'Ghana' && userData?.verifiedKyc === true) ||
      (userData?.country !== 'Ghana' && userData?.verifiedKyc === 'APPROVED') ||
      (userData?.country == 'Ghana' && userData?.kyclevel == 'Level2') ||
      (userData?.country == 'Ghana' && userData?.kyclevel == 'Level3')
    ) {
      setOpen(false);
      setSavingsOpen(true);
    } else {
      dispatch(openUpdateBox());
    }
  };

  const handleActionH1b = () => {
    window.open(
      `https://docs.google.com/forms/d/e/1FAIpQLSdNwK6f7XFIRJNJz-s4zJKdFUYlAxASHY-bevTAMpGKBnMEbg/viewform`,
    );
    setIsH1bModal(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpen(true);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  // useEffect(() => {
  //   // Check if userData is defined and does not contain the nested structure
  //   if (
  //     userData &&
  //     userData.notification?.safeLockAndMiaiEventsNotification === true
  //   ) {
  //     // console.log('safeLockAndMiaiEventsNotification does exist');
  //     setOpen(false);
  //   } else if (
  //     userData &&
  //     userData.notification?.safeLockAndMiaiEventsNotification === undefined
  //   ) {
  //     setOpen(true);
  //     // console.log('safeLockAndMiaiEventsNotification does not  exists');
  //   } else return;

  //   // Indicate that data has been loaded
  //   setDataLoaded(true);
  // }, [userData]);

  // const handleWaitList = async () => {
  //   setOpen(false);
  //   const data = {
  //     key: 'safeLockAndMiaiEventsNotification',
  //     value: true,
  //   };

  //   try {
  //     const res = await api.patch('/customer/notification', data);
  //     console.log(res);
  //     openNotificationWithIcon(
  //       'Addition to waitlist successful',
  //       'Safelock Waitlist',
  //       'success',
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     openNotificationWithIconErr(
  //       'Something went wrong. Please try again later',
  //       'Safelock Waitlist',
  //       'Error',
  //     );
  //   }
  // };

  // const IsCardTrue = useSelector(state => state.Tourguide.openCardTourModal);

  return VestiLoader ? (
    <div className="vloader">
      <div className="vloaderAbsolute">
        <img src={Vloader} alt="" />
      </div>
    </div>
  ) : (
    <>
      <Largemodal onClick={() => setseeallActions(false)} open={seeallactions}>
        <SeeallquickAction />
      </Largemodal>

      {/* Miai pop up */}

      <Simplemodal
        onClick={() => setIsMiaiModal(false)}
        // onClick={() => setOpen(true)}
        handleWaitList
        open={isMiaiModal}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px 20px',
          }}
        >
          <div
            style={{
              background: '#518C36',
              width: '70px',
              height: '70px',
              borderRadius: '50%',
            }}
          >
            <img
              style={{ width: '100%', height: '100%' }}
              src={MiaiAvatar}
              alt=""
            />
          </div>

          <div>
            <h1
              style={{
                color: '#2B5219',
                fontWeight: '500',
                fontSize: '22px',
                margin: '10px 0px',
                textAlign: 'center',
              }}
            >
              Chat with Miai ™
            </h1>
            <p
              style={{
                color: '#2B5219',
                fontWeight: '500',
                fontSize: '13px',
                margin: '0px 0px 20px 0px',
                textAlign: 'center',
              }}
            >
              Need help understanding Vesti's migration services and your
              options? Ask Miai ™, the migration expert
            </p>

            <div style={{ marginTop: '1rem' }}></div>

            <Platformbutton
              name=" Chat with Miai ™"
              type="normal"
              click={() => {
                navigate('/miai');
              }}
            />

            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <ImCheckboxChecked
                style={{
                  background: 'white',
                  color: isChecked ? '#518C36' : 'white',
                  cursor: 'pointer',
                  border: '1px solid #518C36',
                  width: '14px',
                  height: '14px',
                  borderRadius: '2px',
                }}
                onClick={() => handleMiaiPopUp()}
              />{' '}
              <p style={{ color: '#2B5219' }}>Don't remind me again</p>
            </div>
          </div>
        </div>
      </Simplemodal>

      {/* <Simplemodal onClick={() => setIsH1bModal(false)} open={isH1bModal}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px 20px',
          }}
        >
        
          <img src={h1bIcon} alt="" />
    

          <div>
            <h1
              style={{
                color: '#67A948',
                fontWeight: '500',
                fontSize: '22px',
                margin: '20px 0px 10px 0px',
                textAlign: 'center',
              }}
            >
              H1-B Clarity Call and Registration Session
            </h1>
            <p
              style={{
                color: '#2B5219',
                fontWeight: '500',
                fontSize: '13px',
                margin: '0px 0px 20px 0px',
                textAlign: 'center',
              }}
            >
              The 2024 registration is now closed to allow us complete the
              process
            </p>

            <div style={{ marginTop: '1rem' }}></div>

            <Platformbutton
              name="Join Waitlist"
              type="normal"
              click={() => {
                handleActionH1b();
              }}
            />

            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <ImCheckboxChecked
                style={{
                  background: 'white',
                  color: isChecked ? '#518C36' : 'white',
                  cursor: 'pointer',
                  border: '1px solid #518C36',
                  width: '14px',
                  height: '14px',
                  borderRadius: '2px',
                }}
                onClick={() => handleh1BPopUp()}
              />{' '}
              <p style={{ color: '#2B5219' }}>Don't remind me again</p>
            </div>
          </div>
        </div>
      </Simplemodal> */}

      <TourGuidemodal onClick={() => setIsModalOpen(false)} open={isModalOpen}>
        <IntroBankGuide
          setIsModalOpen={setIsModalOpen}
          setTourGuide={setTourGuide}
          setFlightTour={setFlightTour}
        />
      </TourGuidemodal>

      <TourGuidemodal onClick={() => setFlightTour(false)} open={isFlightTour}>
        <FlightTourGuide
          setIsModalOpen={setIsModalOpen}
          setTourGuide={setTourGuide}
          setFlightTour={setFlightTour}
        />
      </TourGuidemodal>

      {/* Deposit Modal */}

      <Simplemodal
        onClick={() =>
          modifyState({
            modal: false,
            currency: 'USD_CENTS',
            numInput: '',
          })
        }
        open={modal}
      >
        <div className="d-flex flex-column text-left  justify-content-center">
          {loading ? (
            <Loader />
          ) : (
            <>
              {currency ? (
                <Depositwallet
                  handleCurrencyChange={handleCurrencyChange}
                  onAmountChange={onInputChange}
                  amount={state.numInput}
                  currency={state?.currency}
                  onClick={handleAddWallet}
                  cb={handleModal}
                  userData={userData}
                  request={accountRequest}
                  // noNgn={true}
                />
              ) : (
                <Depositusdwallet
                  closeModal={handleModal}
                  refetch={refetch}
                  rate={rate}
                  userdata={userData}
                  userType={
                    userData.location === 'US' ||
                    userData.location === 'united states' ||
                    userData.location === 'usa' ||
                    userData.location === 'USA' ||
                    userData.country === 'USA'
                      ? 'us'
                      : ''
                  }
                />
              )}
            </>
          )}
        </div>
      </Simplemodal>

      {/* Withdrawal Modal */}
      <Simplemodal
        onClick={() => closeWithdrawalModal()}
        open={withdrawalModal}
      >
        {/* {addbank ==='withdrawal' ? <Withdrawal close={closeWithdrawalModal} user={userData} refetch={refetch} openAddbank={closeWithopenAddbank} />: <Withaddbank closeModal={openAddbankcloseAddbank}/> }
         */}

        {addbank === 'withdrawal' ? (
          <Ngnwithdrawal
            close={closeWithdrawalModal}
            // balance={userData.walletInNGNKobo}
            balance={
              finalCurrency?.currency === 'NGN' ? finalCurrency?.balance : 0
            }
            user={userData}
            refetch={refetch}
            openAddbank={closeWithopenAddbank}
            currency={'NGN'}
          />
        ) : (
          <Withaddbank
            closeModal={openAddbankcloseAddbank}
            back={() => {
              openAddbankcloseAddbank();
              showWithdrawalModal();
            }}
          />
        )}
      </Simplemodal>

      {/* Virtual Card Modal */}
      {virtualCardModal && (
        <VirtualCardModal
          isOpen={virtualCardModal}
          onClose={() => {
            modifyState({ virtualCardModal: false });
          }}
        />
      )}

      <Layout>
        {/* Newprompt modal */}

        <Simplemodal onClick={() => setOpen(false)} open={open}>
          <Newprompts img={Safe}>
            <div className="innerprompts">
              <div className="innerprompts-tops">
                <p className=" mt-4">
                  Safelock{' '}
                  <span className="bg-[#5261BC] text-white text-[12px] p-1 mb-[10px]">
                    New Feature
                  </span>
                </p>
                <p className=" mt-2 text-center">
                  Earn up to{' '}
                  <span style={{ color: '#5261BC' }}>10% Interest</span> when
                  you use Safelock for your Migration Savings
                </p>
              </div>

              <Platformbutton
                type="normal"
                name="Start Saving"
                click={() => {
                  handleSavingModalStep();
                }}
              />
            </div>
          </Newprompts>
        </Simplemodal>

        {/* providus account detials */}
        <Simplemodal onClick={() => setAccount(!account)} open={account}>
          <Providusaccount
            userData={userData}
            myData={localStorage.getItem('userData')}
          />
        </Simplemodal>

        {/* Add Wallet */}
        <Simplemodal
          onClick={() =>
            dispatch({
              type: WALLET_ACTION,
              payload: { addWallet: false },
            })
          }
          open={props.addWallet}
        >
          <Addwallet
            refetch={refetch}
            onClick={() =>
              dispatch({
                type: WALLET_ACTION,
                payload: { addWallet: false },
              })
            }
          />
        </Simplemodal>

        {/* Financial transaction */}
        <Simplemodal
          onClick={() => {
            foundersRefetch();
            dispatch(walletAction({ open: false }));
          }}
          open={props.open}
        >
          <Financialtrans
            rate={rate}
            foundersBalance={balance / 100}
            phone={userData.phoneNumber}
            sourceId={userData.stripeSourceId}
            refetch={foundersRefetch}
            id={userData.id}
            userdata={userData}
            close={() => {
              foundersRefetch();
              dispatch(walletAction({ open: false }));
            }}
          />
        </Simplemodal>

        {/* add money */}
        <Simplemodal
          onClick={() => {
            dispatch(addMoneyAction({ addMoney: '' }));
          }}
          open={props.addMoney}
        >
          <Addmoney
            allWallet={allWallet}
            rate={rate}
            foundersBalance={balance / 100}
            sourceId={userData.stripeSourceId}
            fRefetch={() => foundersRefetch()}
          />
        </Simplemodal>

        {/* send money */}
        <Simplemodal
          onClick={() => {
            dispatch(sendMoneyAction({ sendMoney: '' }));
          }}
          open={props.sendMoney}
        >
          <Sendmoney
            allWallet={allWallet}
            rate={rate}
            foundersBalance={balance / 100}
            sourceId={userData.stripeSourceId}
            fRefetch={() => foundersRefetch()}
            onClick={() => {
              dispatch(sendMoneyAction({ sendMoney: '' }));
            }}
          />
        </Simplemodal>

        {/* FBO transaction */}
        <Simplemodal
          onClick={() => {
            refetch();
            dispatch(walletUsdAction({ usdOpen: false }));
          }}
          open={props.usdOpen}
        >
          <Fbotransactions
            phone={userData.phoneNumber}
            refetch={refetch}
            id={userData.id}
            userdata={userData}
            close={() => {
              refetch();
              dispatch(walletUsdAction({ usdOpen: false }));
            }}
            closeModal={handleModal}
            rate={rate}
            userType={
              userData.location === 'US' ||
              userData.location === 'united states' ||
              userData.location === 'usa' ||
              userData.location === 'USA' ||
              userData.country === 'USA'
                ? 'us'
                : ''
            }
          />
        </Simplemodal>

        {/* usd modal withdrawal */}
        <Simplemodal onClick={() => closeUsdmodal()} open={usdmodal}>
          <Comingsoon
            title="Under Maintenance"
            subtitle={`
              We are sorry to inform you that our USD Wire service will be on hold for the next 
              few weeks. However, be rest assured that your funds are intact and safe. 
              We would provide you updates where necessary as the situation evolves.
              `}
            button="Close"
            onClick={() => closeUsdmodal()}
          />
        </Simplemodal>

        {/* Create stripe */}
        <Simplemodal
          onClick={() =>
            dispatch(props.openWait ? closeWaitlist() : closeAccWait())
          }
          open={props.openWait ? props.openWait : props.openAccWait}
        >
          {props.openWait ? (
            <Createstripe
              approved={userData.virtualCardWaitlistStatus}
              openWait={props.openWait}
              closeModal={() => dispatch(closeWaitlist())}
              data={dt}
            />
          ) : (
            <Accountwaitlist closeModal={() => dispatch(closeAccWait())} />
          )}
        </Simplemodal>

        {/* Order card */}
        <Simplemodal
          open={props.openVirtual}
          onClick={() => dispatch(closeVirtual())}
        >
          <Ordercard
            refetch={refetch}
            cb={props.fetchAllCards}
            openWait={props.openWait}
            approved={userData.virtualCardWaitlistStatus}
            monoCardHolderId={userData.monoCardHolderId}
            country={userData.country}
            userData={userData}
            balance={finalCurrency?.balance / 100}
            rate={rate}
            closeModal={() => dispatch(closeVirtual())}
          />
        </Simplemodal>

        {/* Fund Card */}
        <Simplemodal
          onClick={() =>
            setFundmodal({
              ...fundmodal,
              value: false,
              id: '',
              name: '',
            })
          }
          open={fundmodal.value}
        >
          <Fundcard
            cardId={fundmodal.id}
            name={fundmodal.name}
            cb={props.fetchAllCards}
            rate={rate}
            wallets={allWallet}
            balance={finalCurrency?.balance / 100}
            currency={fundmodal?.currency}
            closeModal={() =>
              setFundmodal({
                ...fundmodal,
                value: false,
                id: '',
                name: '',
                currency: '',
              })
            }
          />
        </Simplemodal>

        {/* Upload bank form */}
        <Simplemodal onClick={() => setUpload(false)} open={upload}>
          <div className="uploadbankform">
            <Titlesubtitle
              title="Upload Bank form"
              subtitle="Upload the bank form that you filled."
            />
            <p className="bank_form" onClick={() => saveFile()}>
              Missed the bank form ? <strong>Download form again</strong>
            </p>
            <Fileupload
              info="PDF size limit of"
              title="Upload Bank Form"
              id={1}
              value={bankform.document}
              name={bankform.name}
              setValue={handleDoc}
              remove={() => {
                setBankform({
                  ...bankform,
                  document: '',
                  name: '',
                  btn: '',
                });
              }}
              loading={bankform.btn}
              click={uploadDocument}
            />
          </div>
        </Simplemodal>

        {/* partnership */}
        <Simplemodal
          onClick={() => dispatch(handlePartner(false))}
          open={props.handlePartners}
        >
          <Partnership />
        </Simplemodal>

        <Simplemodal onClick={() => setSavingsOpen(false)} open={savingsOpen}>
          <NewSavings close={() => setSavingsOpen(false)} user={userData} />
        </Simplemodal>

        {/* Bank page */}
        <div
          className=" isw-container"
          style={{
            height: '85vh',
            width: '100%',
            overflowY: 'auto',
          }}
        >
          <div className=" flex_page_container d-flex justify-content-center ">
            <div className="px-3 w-100">
              {wallet &&
                wallet?.wallet &&
                supportedWallets?.includes(wallet?.wallet) && (
                  <Stepback onClick={() => navigate('/bank')} />
                )}

              <div className="titleHeader">
                <div className="w-full">
                  <Smalltitle
                    title={
                      wallet?.wallet === 'bank' || !wallet?.wallet
                        ? 'Migration Savings Account'
                        : `Migration Savings ${__renderWalletTitle(
                            wallet?.wallet,
                          )} Account`
                    }
                  />
                </div>

                <Platformbutton
                  name="Add Wallet"
                  type="addwallet"
                  addOns=" --rounded"
                  classname="fas fa-plus-circle"
                  click={() =>
                    dispatch({
                      type: WALLET_ACTION,
                      payload: { addWallet: true },
                    })
                  }
                />
              </div>
              {/* <div className="row "> */}
              <div className="pt-3">
                {/* <div className="col-12 col-md-12 col-lg-12 col-xl-12  pt-3"> */}
                <Wallets
                  nairaAmount={
                    filteredData ? filteredData[0]?.balance / 100 : ''
                  }
                  dollarAmount={userData?.walletAmountInUSCents / 100}
                  accountNumber={
                    filteredData ? filteredData[0]?.bank?.number : ''
                  }
                  request={accountRequest}
                  foundersBalance={balance / 100}
                  depositUsd={() =>
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === true) ||
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === 'APPROVED') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level2') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level3')
                      ? dispatch(
                          walletUsdAction({
                            usdOpen: true,
                            action: 'deposit',
                          }),
                        )
                      : openUpdateModal()
                  }
                  withdrawUsd={() =>
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === true) ||
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === 'APPROVED') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level2') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level3')
                      ? setUsdmodal(true)
                      : openUpdateModal()
                  }
                  sendUsd={() =>
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === true) ||
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === 'APPROVED') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level2') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level3')
                      ? dispatch(
                          walletUsdAction({
                            usdOpen: true,
                            action: 'withdraw',
                          }),
                        )
                      : openUpdateModal()
                  }
                  deposit={() =>
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === true) ||
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === 'APPROVED') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level2') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level3')
                      ? toggle()
                      : openUpdateModal()
                  }
                  send={() =>
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === true) ||
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === 'APPROVED') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level2') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level3')
                      ? redirectTransfer()
                      : openUpdateModal()
                  }
                  withdraw={() =>
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === true) ||
                    (userData?.country !== 'Ghana' &&
                      userData?.verifiedKyc === 'APPROVED') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level2') ||
                    (userData?.country == 'Ghana' &&
                      userData?.kyclevel == 'Level3')
                      ? currency
                        ? showWithdrawalModal()
                        : setUsdmodal(true)
                      : openUpdateModal()
                  }
                  userdata={userData}
                  stripeVirtualAccountNumber={
                    userData.stripeVirtualAccountNumber
                  }
                  nairaAccount={() => setAccount(true)}
                  type={
                    supportedWallets.includes(wallet?.wallet) ||
                    supportedWallets.includes(`/bank?wallet=${wallet?.wallet}`)
                      ? wallet?.wallet || `/bank?wallet=${wallet?.wallet}`
                      : 'bank'
                  }
                  addMoney={handleAddMoney}
                  sendMoney={handleSendMoney}
                />
              </div>
              {/* </div> */}
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                  <Quickfeature
                    setseeallActions={setseeallActions}
                    create={
                      (userData?.country !== 'Ghana' &&
                        userData?.verifiedKyc === true) ||
                      (userData?.country !== 'Ghana' &&
                        userData?.verifiedKyc === 'APPROVED') ||
                      (userData?.country == 'Ghana' &&
                        userData?.kyclevel == 'Level2') ||
                      (userData?.country == 'Ghana' &&
                        userData?.kyclevel == 'Level3')
                        ? value => dispatch(openVirtual(value))
                        : openUpdateModal
                    }
                  />

                  <Payment userData={userData} refetch={refetch} />

                  <Domore userdata={userData} />

                  <Transtable
                    balance={finalCurrency?.balance}
                    id={userData.id}
                    type={finalCurrency ? finalCurrency?.currency : 'bank'}
                    // currency={finalCurrency?.currency}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = ({
  auth,
  common,
  transactions,
  domore,
  wallets,
  cards,
}) => {
  const { authUser, userData } = auth;
  const transactionsData = transactions;
  const { loading } = common;
  const { openVirtual, openAccWait, openWait, handlePartners } = domore;
  const { action, open, usdOpen, addMoney, sendMoney, addWallet } = wallets;
  const { allCards, loading: load } = cards;
  return {
    authUser,
    loading,
    transactionsData,
    openVirtual,
    openAccWait,
    openWait,
    action,
    open,
    usdOpen,
    allCards,
    load,
    handlePartners,
    userData,
    addMoney,
    sendMoney,
    addWallet,
  };
};

const mapDispatchToProps = {
  addCashToWallet,
  showAuthLoader,
  getProfile,
  fetchTransactions,
  openUpdateBox,
  fetchAllCards,
  handlePartner,
  getUser,
  fetchUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cash);
