import { Titlesubtitle } from 'components/common/titlesubtitle/titlesubtitle';
import React, { useState } from 'react';
import world from 'assets/purplepatch.svg';
import patch from 'assets/cyanworld.svg';
import { navigate } from '@reach/router';
import './payment.scss';
import { Largemodal } from 'components/common/simplifiedmodal';
import CableMain from 'containers/Admin/BillPayment/CableTv/CableTv';
import ElectricityMain from 'containers/Admin/BillPayment/Electricity/ElectricityMain';
import Internet from 'containers/Admin/BillPayment/DataPurchase/Internet';
import AirtimeMain from 'containers/Admin/BillPayment/Airtime/Airtime';
import { Smalltitle } from 'components/common/titlesubtitle/titlesubtitle';
import { openUpdateBox } from 'appRedux/actions/update';
import { useDispatch } from 'react-redux';

export const Payment = props => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleBillAction = () => {
    if (
      (props.userData?.country !== 'Ghana' &&
        props.userData?.verifiedKyc === true) ||
      (props.userData?.country !== 'Ghana' &&
        props.userData?.verifiedKyc === 'APPROVED') ||
      (props.userData?.country == 'Ghana' &&
        props.userData?.kyclevel == 'Level2') ||
      (props.userData?.country == 'Ghana' &&
        props.userData?.kyclevel == 'Level3')
    ) {
      setOpen(true);
    } else {
      dispatch(openUpdateBox());
    }
  };

  return (
    <section className="pymnt">
      <div style={{ marginBottom: '50px' }}>
        <Smalltitle title="Payments" />
      </div>
      {/* <Titlesubtitle title="Payments" /> */}
      <div className="pymnt__main">
        <div className="pymnt__main__cardrow">
          <SingleCard
            title="Bills Payments"
            titleColor="#20082B"
            subtitle="Easily pay for cable tv, phone, internet, sewage and others, filter by country."
            subtitleColor="20082B"
            borderColor="0.5px solid #6F368B"
            image={world}
            cta="Start paying"
            ctaColor="#57266E"
            background="#FBF3FF"
            onClick={() => handleBillAction()}
          />
          <SingleCard
            title="Migration Fees"
            titleColor="#024750"
            subtitle="Pay more than 100 Migration payments in your local currency."
            subtitleColor="#024750"
            borderColor="0.5px solid #138694"
            image={patch}
            cta="Explore fees"
            ctaColor="#096672"
            background="#C4F8FF"
            onClick={() => navigate('/merchants')}
          />
        </div>
        <Largemodal open={open} onClick={() => setOpen(false)}>
          <Titlesubtitle
            title="Categories"
            subtitle="Select from the list below"
          />
          <div className="billpayment-container-inner pt-2">
            <div className="arrangement flex flex-wrap mt-4 justify-between">
              <CableMain />
              <ElectricityMain />
              <Internet />
              <AirtimeMain />
            </div>
          </div>
        </Largemodal>
      </div>
    </section>
  );
};

const SingleCard = props => {
  return (
    <div
      className="card_body "
      style={{
        backgroundColor: props.background,
        border: props.borderColor,
        borderRadius: '10px',
      }}
    >
      <div className="card_top">
        <p style={{ color: props.titleColor }}>{props.title}</p>
        <p style={{ color: props.subtitleColor }}>{props.subtitle}</p>
      </div>
      <div className="card-bottom">
        <p style={{ color: props.ctaColor }} onClick={() => props.onClick()}>
          {props.cta}
          <i className="fas fa-arrow-up"></i>
        </p>
        <div className="card-image">
          <img className="img-fluid" src={props.image} alt="card" />
        </div>
      </div>
    </div>
  );
};
